import { createStitches } from "@stitches/react";
import { reset } from "stitches-reset";

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      gray400: "gainsboro",
      gray500: "lightgray",
    },
  },
  media: {
    tablet: "(min-width: 360px)",
    desktop: "(min-width: 740px)",
  },
  utils: {
    m: (value: any) => ({ margin: value }),
    mx: (value: any) => ({ marginLeft: value, marginRight: value }),
    my: (value: any) => ({ marginTop: value, marginBottom: value }),
    p: (value: any) => ({ padding: value }),
    px: (value: any) => ({ paddingLeft: value, paddingRight: value }),
    py: (value: any) => ({ paddingTop: value, paddingBottom: value }),
  },
});

export const globalReset = globalCss(reset);
export const globalStyles = globalCss({
  "html, body": {
    background: "#101010",
    fontSize: 16,
    fontFamily: `system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    height: "100%",
  },
  "#root": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100%",
    height: "100%",
  },
});
