import React from "react";
import ReactDOM from "react-dom/client";
import { globalReset, globalStyles } from "./config/stitches.config";

import App from "./App";

// Reset
globalReset();
globalStyles();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
