import SkidmarkLogo from "./skidmark-horizontal-light.svg";
import Advisory from "./flavor-advisory.svg";
import { styled } from "./config/stitches.config";

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#101010",
  color: "#fff",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  height: "100%",
  width: "100%",
});

const Header = styled("header", {
  padding: 24,
});

const Logo = styled("img", {
  userSelect: "none",
  pointerEvents: "none",
  width: 275,
  maxWidth: "100%",

  "@tablet": {
    width: 500,
  },

  "@desktop": {
    width: 800,
  },
});

const FlavorAdvisory = styled("img", {
  userSelect: "none",
  pointerEvents: "none",
  position: "absolute",
  bottom: 24,
  right: 24,
  width: 100,

  "@tablet": {
    width: 100,
  },

  "@desktop": {
    width: 150,
  },
});

function App() {
  return (
    <Wrapper>
      <Header>
        <Logo src={SkidmarkLogo} alt="Skidmark Sauces LLC" />
      </Header>
      <FlavorAdvisory
        src={Advisory}
        alt="Flavor Advisory - Sauce Irresponsibly"
      />
    </Wrapper>
  );
}

export default App;
